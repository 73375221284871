/*.modal-open{overflow:hidden}*/

/*.modal{position:fixed;top:50%;right:0;bottom:0;left:50%;z-index:1050;display:none;overflow:hidden;outline:0;transform: translate(-50%, -50%);}*/

/*.modal-open .modal{overflow-x:hidden;overflow-y:auto}*/

/*.modal-dialog{position:relative;margin:.5rem;pointer-events:none;min-width:500px;width:fit-content}*/

/*.modal.fade .modal-dialog{transition:-webkit-transform .3s ease-out;transition:transform .3s ease-out,-webkit-transform .3s ease-out;-webkit-transform:translate(0,-25%);transform:translate(0,-25%)}*/
/*@media screen and (prefers-reduced-motion:reduce){.modal.fade .modal-dialog{transition:none}}*/

/*.modal.show .modal-dialog{-webkit-transform:translate(0,0);transform:translate(0,0)}*/
/*.modal-dialog-centered{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;min-height:calc(100% - (.5rem * 2))}*/

/*.modal-content{position:relative;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;pointer-events:auto;background-color:#fff;background-clip:padding-box;border:1px solid rgba(0,0,0,.2);border-radius:.3rem;outline:0;padding:40px}*/

/*.modal-backdrop{position:fixed;top:0;right:0;bottom:0;left:0;z-index:1040;background-color:#000}*/
/*.modal-backdrop.fade{opacity:0}*/
/*.modal-backdrop.show{opacity:.5}*/

/*.modal-header{display:-ms-flexbox;display:flex;-ms-flex-align:start;align-items:flex-start;-ms-flex-pack:justify;justify-content:space-between;padding:1rem;border-bottom:1px solid #e9ecef;border-top-left-radius:.3rem;border-top-right-radius:.3rem}*/
/*.modal-header .close{padding:1rem;margin:-1rem -1rem -1rem auto}*/

/*.modal-title{margin-bottom:0;line-height:1.5}*/

/*.modal-body{position:relative;-ms-flex:1 1 auto;flex:1 1 auto;padding:1rem}*/

/*.modal-footer{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;padding:1rem;border-top:1px solid #e9ecef}*/
/*.modal-footer>:not(:first-child){margin-left:.25rem}.modal-footer>:not(:last-child){margin-right:.25rem}*/

/*@media (min-width:576px){.modal-dialog{margin:1.75rem auto;}.modal-dialog-centered{min-height:calc(100% - (1.75rem * 2))}}*/

/*@media (max-width: 768px){*/
/*    .modal-dialog{*/
/*        max-width:95%;*/
/*        min-width: 95%;*/
/*        margin:5px auto 5px auto;*/
/*    }*/
/*    .modal-content{*/
/*        padding:10px*/
/*    }*/
/*    .modal {*/
/*        min-width: 95%;*/
/*    }*/
/*}*/

.modal {
    font-family: "Oswald", sans-serif;
    font-weight: 100;
}

.modal .modal-title {
    font-weight: 700;
    font-size: 35px;
}

.modal {
    display: flex !important;
    vertical-align: middle; }
.modal .modal-dialog {
    margin: auto; }
.modal .modal-header {
    display: initial;
    text-align: center;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 2em; }
.modal .modal-header .modal-title {
    display: inline-block;
    color: #000000;
    font-family: "Oswald", sans-serif; }
.modal .modal-body {
    padding: 0;
    font-family: "Oswald", sans-serif; }
.modal .modal-body .question-box.question-form {
    border: none;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 2em; }
.modal .modal-body .question-box.question-form .form-control {
    height: 3em; }
.modal .modal-body .question-box.question-form .btn.btn-default.btn-admin {
    font-family: "Oswald", sans-serif;
    background: #0000ff;
    color: #000000;
    width: 65%;
    height: 3em;
    border: none;
}

.transparentBackground {
    background-color: transparent !important;
    border: none !important;
}
